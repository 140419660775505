<!-- =========================================================================================
  File Name: ECommerceCheckout.vue
  Description: eCommerce Checkout page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="ecommerce-checkout-demo">
    <form-wizard
      ref="checkoutWizard"
      :hide-buttons="true"
      :subtitle="null"
      :title="null"
      color="rgba(var(--vs-primary), 1)">

      <!-- tab 1 content -->
      <tab-content class="mb-5" icon="feather icon-shopping-cart" title="Cart">

        <!-- IF CART HAVE ITEMS -->
        <div v-if="cartItems.length" class="vx-row">

          <!-- LEFT COL -->
          <div class="vx-col lg:w-2/3 w-full relative">
            <div v-for="(item, index) in cartItems" :key="item.id" class="items-list-view">
              <item-list-view :item="item" class="mb-base">

                <!-- SLOT: ITEM META -->
                <template slot="item-meta">
                  <h6 class="item-name font-semibold mb-1 cursor-pointer hover:text-primary"
                      @click="$router.push(`/shop/${item.id}`).catch(() => {})">{{ item.name }}</h6>
                  <p class="text-sm mb-2">
                    By <span class="font-semibold cursor-pointer">{{ item.service.name }}</span>
                  </p>

                  <nl2br :text="item.description" class-name="item-description text-sm" tag="p"/>
                </template>

                <!-- SLOT: ACTION BUTTONS -->
                <template slot="action-buttons">

                  <!-- PRIMARY BUTTON: REMOVE -->
                  <div
                    class="item-view-primary-action-btn p-3 rounded-lg flex flex-grow items-center justify-center cursor-pointer mb-3"
                    @click="removeItemFromCart(item)">
                    <feather-icon icon="XIcon" svgClasses="h-4 w-4"/>
                    <span class="text-sm font-semibold ml-2">REMOVE</span>
                  </div>
                </template>
              </item-list-view>
            </div>
          </div>

          <!-- RIGHT COL -->
          <div class="vx-col lg:w-1/3 w-full">
            <vx-card>
              <div class="flex justify-between">
                <h4 class="font-semibold">Coupons</h4>
                <h4 class="text-primary cursor-pointer font-light" @click="coupon_popup = !coupon_popup">Apply</h4>
              </div>

              <div class="flex items-center mt-4" v-if="coupon_result.success">
                <feather-icon v-if="coupon_result.success === true" icon="CheckCircleIcon" svgClasses="h-8 w-8 text-success"></feather-icon>
                <h5 v-if="coupon_result.message" class="ml-2">{{coupon_result.message}}</h5>
              </div>

              <!--- <h4 v-if="coupon_result.success === true" class="font-bold mt-2">New Total: <span class="font-normal">${{coupon_details.price.total}} USD</span></h4> --->

              <vs-divider/>

              <h4 class="font-semibold mb-3">Price Details</h4>
              <div v-for="(item, index) in cartItems" :key="item.id" class="flex justify-between mb-2">
                <span class="text-grey">{{ item.name }}</span>
                <span>{{ item.price_display }}</span>
              </div>

              <vs-divider/>


              <div v-if="referred && !coupon_result.success" class="flex justify-between mb-2">
                <h4>Referral Discount</h4>
                <h4 class="font-light text-success">- {{ (totalValueNoDiscount - totalValueRefDiscount) | currency }} USD</h4>
              </div>

              <div class="flex justify-between font-semibold mb-3" v-if="coupon_result.success === true">
                <h4>Coupon Discount</h4>
                <h4 class="font-light text-success">- {{coupon_details.price.discount | currency}} USD</h4>
              </div>

              <div class="flex justify-between font-semibold mb-3">

                <h4>Total</h4>
                <h4 class="font-light">{{ totalValue | currency }} USD</h4>

              </div>

              <vs-button class="w-full mt-2" @click="$refs.checkoutWizard.nextTab()">
                CONTINUE TO ADDRESS
              </vs-button>
            </vx-card>
          </div>
        </div>

        <!-- IF NO ITEMS IN CART -->
        <vx-card v-else title="You don't have any items in your cart.">
          <vs-button @click="$router.push('/shop').catch(() => {})">Browse Shop</vs-button>
        </vx-card>

      </tab-content>

      <!-- tab 2 content -->
      <tab-content class="mb-5" icon="feather icon-home" title="Address">

        <div class="vx-row">

          <!-- LEFT COL: NEW ADDRESS -->
          <div class="vx-col lg:w-2/3 w-full">
            <vx-card class="mb-base"
                     subtitle="Be sure to check &quot;Deliver to this address&quot; when you have finished"
                     title="Add New Address">

              <form data-vv-scope="add-new-address">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/2 w-full">

                    <vs-input
                      v-model="fullName"
                      v-validate="'required|alpha_spaces'"
                      class="w-full mt-5"
                      data-vv-as="field"
                      label="Full Name:"
                      name="fullName"/>
                    <span v-show="errors.has('add-new-address.fullName')" class="text-danger">{{
                        errors.first('add-new-address.fullName')
                      }}</span>

                  </div>

                  <div class="vx-col sm:w-1/2 w-full">

                    <vs-input
                      v-model="mobileNum"
                      v-validate="'required'"
                      class="w-full mt-5"
                      label="Mobile Number:"
                      name="mobileNum"/>
                    <span v-show="errors.has('add-new-address.mobileNum')" class="text-danger">{{
                        errors.first('add-new-address.mobileNum')
                      }}</span>
                  </div>

                </div>

                <div class="vx-row">


                  <div class="vx-col sm:w-1/2 w-full">

                    <vs-input
                      v-model="street1"
                      v-validate="'required'"
                      class="w-full mt-5"
                      label="Street 1"
                      name="street1"/>
                    <span v-show="errors.has('add-new-address.street1')" class="text-danger">{{
                        errors.first('add-new-address.street1')
                      }}</span>
                  </div>

                  <div class="vx-col sm:w-1/2 w-full">

                    <vs-input
                      v-model="street2"
                      class="w-full mt-5"
                      label="Street 2:"
                      name="street2"/>
                  </div>

                </div>

                <div class="vx-row">

                  <div class="vx-col sm:w-1/2 w-full">

                    <vs-input
                      v-model="city"
                      v-validate="'required'"
                      class="w-full mt-5"
                      label="Town/City:"
                      name="city"/>
                    <span v-show="errors.has('add-new-address.city')" class="text-danger">{{
                        errors.first('add-new-address.city')
                      }}</span>
                  </div>

                  <div class="vx-col sm:w-1/2 w-full">
                    <vs-input
                      v-model="postal_code"
                      v-validate="'required|min:3|max:7|alpha_num'"
                      class="w-full mt-5"
                      label="Zip / Postal Code:"
                      name="zip"/>
                    <span v-show="errors.has('add-new-address.zip')" class="text-danger">{{
                        errors.first('add-new-address.zip')
                      }}</span>
                  </div>
                </div>

                <div class="vx-row">


                  <div class="vx-col sm:w-1/2 w-full">

                    <vs-input
                      v-model="state"
                      v-validate="'required'"
                      class="w-full mt-5"
                      label="State:"
                      name="state"/>
                    <span v-show="errors.has('add-new-address.state')" class="text-danger">{{
                        errors.first('add-new-address.state')
                      }}</span>
                  </div>

                  <div class="vx-col sm:w-1/2 w-full">

                    <vs-input
                      v-model="country"
                      v-validate="'required'"
                      class="w-full mt-5"
                      label="Country:"
                      name="country"/>
                    <span v-show="errors.has('add-new-address.country')" class="text-danger">{{
                        errors.first('add-new-address.country')
                      }}</span>
                  </div>

                </div>
                <vs-button class="mt-6 ml-auto flex" @click.prevent="submitNewAddressForm">SAVE TO PROFILE</vs-button>
              </form>
            </vx-card>
          </div>

          <!-- RIGHT COL: CONTINUE WITH SAVED ADDRESS -->
          <div class="vx-col lg:w-1/3 w-full">
            <vx-card :title="fullName">
              <div>
                <p>{{ street1 }}</p>
                <p v-if="street2">{{ street2 }}</p>
                <p>{{ city |title }}, {{ state | capitalize }} {{ postal_code }}</p>
                <p>{{ mobileNum | phone }}</p>
              </div>

              <vs-divider/>

              <vs-button :disabled="!valid_address" class="w-full" @click="$refs.checkoutWizard.nextTab()">
                CONTINUE TO PAYMENT
              </vs-button>
            </vx-card>
          </div>

        </div>
      </tab-content>

      <!-- tab 3 content -->
      <tab-content class="mb-5" icon="feather icon-credit-card" title="Payment">

        <div class="vx-row">

          <!-- LEFT COL: PAYMENT OPTIONS -->
          <div class="vx-col lg:w-2/3 w-full">
            <vx-card class="mb-base" title="Payment Options">

              <div class="mt-3">
                <ul>
                  <!-- OPTION 1 -->
                  <li v-for="(method, index) in this.methods" :key="method.id">
                    <!-- CARD DETAILS -->
                    <div class="flex flex-wrap justify-between items-center">
                      <div class="flex items-center">
                        <span>{{ method.card.country | capitalize }} {{
                            method.card.brand | title
                          }} {{ method.card.funding | title }} Card &mdash; X-{{ method.card.last4 }}</span>
                      </div>
                      <span>{{ method.card.exp_month }}/{{ method.card.exp_year }}</span>
                    </div>
                    <vs-divider class="my-6"/>
                  </li>

                  <li v-if="this.methods.length === 0">
                    <div class="flex flex-wrap justify-between items-center">
                      <div class="flex items-center">
                        <span>There doesn't seem to be any payment methods on your account.</span>
                      </div>
                      <span>
                        <vs-button @click="$router.push('/settings?tab=payment')">Add one Now</vs-button>
                      </span>
                    </div>
                    <vs-divider class="my-6"/>
                  </li>


                </ul>

              </div>
            </vx-card>
          </div>

          <!-- RIGHT COL: PRICE -->
          <div class="vx-col lg:w-1/3 w-full">
            <vx-card>
              <div class="flex justify-between">
                <h4 class="font-semibold">Coupons</h4>
                <h4 class="text-primary cursor-pointer font-light" @click="coupon_popup = !coupon_popup">Apply</h4>
              </div>

              <div class="flex items-center mt-4" v-if="coupon_result.success">
                <feather-icon v-if="coupon_result.success === true" icon="CheckCircleIcon" svgClasses="h-8 w-8 text-success"></feather-icon>
                <h5 v-if="coupon_result.message" class="ml-2">{{coupon_result.message}}</h5>
              </div>

              <!--- <h4 v-if="coupon_result.success === true" class="font-bold mt-2">New Total: <span class="font-normal">${{coupon_details.price.total}} USD</span></h4> --->

              <vs-divider/>

              <h4 class="font-semibold mb-3">Price Details</h4>
              <div v-for="(item, index) in cartItems" :key="item.id" class="flex justify-between mb-2">
                <span class="text-grey">{{ item.name }}</span>
                <span>{{ item.price_display }}</span>
              </div>

              <vs-divider/>


              <div v-if="referred && !coupon_result.success" class="flex justify-between mb-2">
                <h4>Referral Discount</h4>
                <h4 class="font-light text-success">- {{ (totalValueNoDiscount - totalValueRefDiscount) | currency }} USD</h4>
              </div>

              <div class="flex justify-between font-semibold mb-3" v-if="coupon_result.success === true">
                <h4>Coupon Discount</h4>
                <h4 class="font-light text-success">- {{coupon_details.price.discount | currency}} USD</h4>
              </div>

              <div class="flex justify-between font-semibold mb-3">

                <h4>Total</h4>
                <h4 class="font-light">{{ totalValue | currency }} USD</h4>

              </div>

              <vs-button class="w-full" @click="this.makePayment">PLACE ORDER</vs-button>

            </vx-card>
          </div>
        </div>
      </tab-content>

    </form-wizard>

    <vs-popup :active.sync="coupon_popup" title="Apply Coupon" id="ApplyCouponPopup">
      <h2 class="mb-5 font-light">Enter Coupon Code Below</h2>
      <vs-input v-model="coupon_code" class="w-full" placeholder="Enter Coupon"/>

      <div class="flex items-center mt-5" v-if="coupon_result.success === false">
        <feather-icon v-if="coupon_result.success === false" icon="XCircleIcon" svgClasses="h-8 w-8 text-danger"></feather-icon>
        <h5 v-if="coupon_result.message" class="ml-2">{{coupon_result.message}}</h5>
      </div>

      <div class="flex items-center mt-8">
        <vs-button color="success" icon-pack="feather" icon="icon-check-circle" class="mr-2" @click="submitCoupon">Apply Coupon</vs-button>
        <vs-button color="danger" icon-pack="feather" icon="icon-x-circle" class="ml-2" @click="removeCoupon">Remove Coupon</vs-button>
      </div>

    </vs-popup>

  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

const ItemListView = () => import('./components/ItemListView.vue');

export default {
  data() {
    return {
      discount: 0,
      referred: false,

      // coupons
      coupon_popup: false,
      coupon_code: '',
      coupon_result: {
        success: null,
        message: ''
      },
      coupon_details: {},

      // TAB 2
      fullName: '',
      mobileNum: '',
      postal_code: '',
      street1: '',
      street2: '',
      area: '',
      city: '',
      state: '',
      country: '',
      addressType: 1,
      addressTypeOptions: [
        {text: 'Home', value: 1},
        {text: 'Office', value: 2},
      ],
      modified: 0,

      methods: [],

      valid_address: false,

      // TAB 3
      paymentMethod: 'debit-card',
      cvv: '',
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    totalValue() {
      if (this.cartItems.length === 0) return 0;
      if (this.coupon_result.success) {
        return (this.cartItems
          .map(item => {
            return item.price.amount;
          })) - this.discount
      }
      return (this.cartItems
        .map(item => {
          return item.price.amount;
        })
        .reduce((a, b) => a + b) * (this.referred ? .95 : 1))
    },
    totalValueRefDiscount() {
      if (this.cartItems.length === 0) return 0;
      return (this.cartItems
        .map(item => {
          return item.price.amount;
        })
        .reduce((a, b) => a + b) * (this.referred ? .95 : 1))
    },
    totalValueNoDiscount() {
      if (this.cartItems.length === 0) return 0;
      return this.cartItems
        .map(item => {
          return item.price.amount;
        })
        .reduce((a, b) => a + b);
    },
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },
  },
  mounted() {
    this.loadAttachedMethods();
    this.loadAddress();

    if (this.cartItems.length > 0) {
      const referrerCode = localStorage.getItem('referrer');
      if (referrerCode) {

        this.$http.post(`users/@me`, {referrer: referrerCode})
          .then(response => {

            if (response.status === 200) {

              const payload = response.data.data;

              if (payload.affiliate_id) {
                this.referred = true;

              /*  return this.$vs.notify({
                  color: 'success',
                  title: 'Referrer Applied',
                  text: `You were referred using "${referrerCode}". You get 5% off of your order for this!`
                });

               */
              }

            }

          })
          .catch(exception => {

            if (exception.response) {
              const response = exception.response;

              if (response.status === 400) {
                console.error(`No affiliate found using the supplied referring ID.`);
              }


            }


          })

      }
    }

  },
  methods: {
    removeCoupon () {
      this.coupon_result.success = null
      this.coupon_result.message = ''

    },
    submitCoupon () {

      if(this.coupon_code === '') {
        this.coupon_result.success = false
        this.coupon_result.message = 'Please Enter a Coupon Code'
        this.coupon_details = {}
        this.discount = 0

        return
      }

      let payload = {
        prices: [],
        products: [],
        coupon: this.coupon_code
      };

      this.cartItems.forEach(item => {

        if (!item.price_id) {
          this.$store.dispatch('eCommerce/toggleItemInCart', item);

          return this.$vs.notify({
            title: 'An unknown error occurred while checking out',
            text: 'Please re-add the selected items to your card',
            color: 'danger',
          });
        }

        payload.prices.push(item.price_id);
      });


      this.$http.get(`billing/coupons/code/${payload.coupon}?priceId=${payload.prices[0]}`)
        .then(response => {
          if (response.status === 200) {
            this.coupon_details = response.data.data;
            this.coupon_result.success = true
            this.coupon_result.message = 'Coupon Applied Successfully'
            this.discount = this.coupon_details.price.discount
            this.coupon_popup = false
          }
        }).catch((exception) => {

        let error = 'Coupon Could Not Be Applied'
        if (exception.response) {
          error = exception.response.data.error.description
        }

        this.discount = 0
        this.coupon_result.success = false
        this.coupon_result.message = error
      });


    },
    submitAddress() {
      this.$http.post(`users/@me/customer/address`, {
        line1: this.street1,
        line2: this.street2,
        country: this.country,
        state: this.state,
        city: this.city,
        postal_code: this.postal_code,
      })
        .then(response => {
          if (response.status === 200) {
            this.valid_address = true;
            this.$refs.checkoutWizard.nextTab()
          }
        }).catch(exception => {

      });
    },
    loadAddress() {

      this.$http.get(`users/@me/customer/address`)
        .then(response => {
          if (response.status === 200 && response.data.data) {
            const d = response.data.data;
            this.fullName = `${this.activeUserInfo.first_name} ${this.activeUserInfo.last_name}`;
            this.mobileNum = this.activeUserInfo.phone_number;
            this.street1 = d.line1;
            this.street2 = d.line2;
            this.country = d.country;
            this.state = d.state;
            this.city = d.city;
            this.postal_code = d.postal_code;
            this.valid_address = true;
          }

        });

    },
    loadAttachedMethods() {

      this.$http.get(`users/@me/customer/methods`)
        .then(response => {

          if (response.status === 200) {
            response.data.data.forEach(method => {
              this.methods.push(method);
            });
          }

        });

    },

    // TAB 1
    removeItemFromCart(item) {
      this.$store.dispatch('eCommerce/toggleItemInCart', item);
    },

    // TAB 2
    submitNewAddressForm() {
      return this.submitAddress();
    },

    // TAB 3
    makePayment() {

      let total = this.totalValue;

      return new Promise(() => {

        let payload = {
          prices: [],
          products: [],
        };

        if (localStorage.getItem('referrer')) {
          payload.apply_affiliate = true;
        }

        if(this.coupon_result.success === true) {
          payload.coupon = this.coupon_details.code
        }

        this.cartItems.forEach(item => {

          if (!item.price_id) {
            this.$store.dispatch('eCommerce/toggleItemInCart', item);

            return this.$vs.notify({
              title: 'An unknown error occurred while checking out',
              text: 'Please re-add the selected items to your card',
              color: 'danger',
            });
          }

          payload.prices.push(item.price_id);
        });
        this.$vs.loading();

        this.$http.post(`billing/checkout/stripe`, payload)
          .then(response => {
            this.$vs.loading.close();

            if (response.status === 200) {
              localStorage.removeItem('referrer');

              const data = response.data.data;

              // noinspection EqualityComparisonWithCoercionJS
              if (data.single_purchase == false && data.subscription == false) {
                return this.$vs.notify({
                  title: 'Failed to purchase',
                  text: 'An unknown error occurred while checking out',
                  color: 'danger',
                  position: 'top-right',
                });
              }

              this.$gtag.purchase({
                transaction_id: data.subscription ? data.subscription.id : 'unknown',
                affiliation: localStorage.getItem('referrer') || 'organic',
                value: total,
              });

              this.cartItems.forEach(item => this.$store.dispatch('eCommerce/toggleItemInCart', item));

              this.$vs.dialog({
                color: 'success',
                title: `Order Processing`,
                text: 'Please watch your email for a notice of successful payment for your invoice. Once you receive this email, you will have the option to view your invoice, as well as use your service. If you do not receive this email, no problem! You can view your information in a moment.',
                accept: () => {
                  this.$router.push('/invoices');
                },
              });



            }

          })
          .catch(exception => {
            this.$vs.loading.close();

            let error = 'An unknown error occurred while checking out';
            if (exception.response) {
              if (exception.response.data.data) {
                error = exception.response.data.data.message;
              } else if (exception.response.data.error) {
                error = exception.response.data.error.description;
              }
            }

            return this.$vs.notify({
              title: 'Failed to checkout',
              text: error,
              color: 'danger',
              position: 'top-right',
            });
          });

      });
    },
  },
  components: {
    ItemListView,
    FormWizard,
    TabContent,
  },
};
</script>

<style lang="scss">
#ecommerce-checkout-demo {
  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
  }

  .item-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  .vue-form-wizard {
    padding-bottom: 0;

    ::v-deep .wizard-header {
      padding: 0;
    }

    ::v-deep .wizard-tab-content {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;

      .wizard-tab-container {
        margin-bottom: 0 !important;
      }
    }
  }
}

#ApplyCouponPopup {
  input.vs-inputx.vs-input--input {
    background: #1B1E22!important;
    border: 1px solid #ffffff!important;

    &:focus {
      border: 1px solid rgba(var(--vs-primary),1)!important;
    }
  }
}
</style>
